import React from "react";

import styles from "./RiskAssessmentSection.module.css";

import FeatureBox from "./FeatureBox";

const features = [
  {
    imgSrc:
      "images/4. Info gif 1 invoice.gif",

    title: "Effective Risk Evaluation",

    description:
      "Empowering you to identify, assess, and management risks with precision and confidence.",
  },

  {
    imgSrc:
      "images/4. Info gif 2 graphic-reviews.gif",

    title: "Continual Monitoring",

    description:
      "Effortlessly analyze and evaluate risks using our comprehensive risk assessment software.",
  },

  {
    imgSrc:
      "images/4. Info gif 3 risk-mitigation.gif",

    title: "Threat Mitigation",

    description:
      "Create effective mitigation strategies to minimize the impact of identified risks.",
  },
];

function RiskAssessmentSection() {
  return (
    <section className={styles.riskAssessmentSection} id="features">
      <div className={styles.goldBackground}>
        <div className={styles.contentWrapper}>
          <h2 className={styles.sectionHeader}>
            Simplify Risk Assessment with Risk-IO
          </h2>

          <p className={styles.sectionDescription}>
            Risk-IO was created to simplify the risk assessment process without
            compromising standards of quality and thoroughness. The application
            fulfils the entire risk manamente process in a single platform
            through:
          </p>
        </div>
      </div>

      <div className={styles.featureContainer}>
        <div className={styles.featureGrid}>
          {features.map((feature, index) => (
            <FeatureBox
              key={index}
              imgSrc={feature.imgSrc}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default RiskAssessmentSection;
