import React, { useState } from "react";
import styles from "./Navbar.module.css";
import NavLink from "./NavLink";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navItems = [
    { text: "FEATURES", className: styles.features, href: "#features" },
    { text: "BENEFITS", className: styles.benefits, href: "#benefits" },
    { text: "RISK-IO AT WORK", className: styles.riskIoAtWork, href: "#atWork" },
  ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={styles.navbar}>
      <img
        loading="lazy"
        src="images/1. Navbar Logo.png"
        className={styles.logo}
        alt="Company logo"
      />

      <NavLink isHamburger onClick={toggleMenu} />

      <div
        className={`${styles.navLinks} ${isOpen ? styles.open : ""}`}
      >
        {navItems.map((item, index) => (
          <NavLink key={index} className={item.className} href={item.href}>
            {item.text}
          </NavLink>
        ))}
      </div>
    </nav>
  );
};

export default Navbar;
