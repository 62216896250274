import React, { useEffect, useState } from "react";
import styles from "./ScrollToTop.module.css";

const ScrollToTop = () => {
  const [isVisible, setIsVisibile] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisibile(true);
    } else {
      setIsVisibile(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div>
      {isVisible && (
        <button onClick={scrollToTop} className={styles.scrollToTop}>
          ↑
        </button>
      )}
    </div>
  );
};

export default ScrollToTop;
