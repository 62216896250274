import React from "react";
import styles from "./Navbar.module.css";

const NavLink = ({ children, className, isHamburger, onClick, href }) => {
  if (isHamburger) {
    return (
      <div className={styles.hamburger} onClick={onClick}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }

  return (
    <a href={href} className={`${styles.navLink} ${className}`}>
      {children}
    </a>
  );
};

export default NavLink;
