import React from "react";

import styles from "./KeyFeaturesSection.module.css";

import FeatureCard from "./FeatureCard";
import FlipCardContainer from "./FlipCardContainer";

const KeyFeaturesSection = () => {

  return (
    <section className={styles.keyFeaturesSection} id="benefits">
      <div className={styles.container}>
        <header className={styles.header}>Key Features</header>

        <p className={styles.description}>
          Discover how our cutting-edge risk management app transforms
          challenges into opportunities, equipping your team with real-time
          insights and streamlined processes to safeguard your business's future
          with confidence.
        </p>

        <FlipCardContainer />
        {/*<div className={styles.featuresContainer}>
          {features.map((feature, index) => (
            <FeatureCard
              key={index}
              //backgroundImage={feature.backgroundImage}
              iconImage={feature.iconImage}
              title={feature.title}
              backText={feature.backText}
            />
          ))}
        </div>*/}
      </div>
    </section>
  );
};

export default KeyFeaturesSection;
