import React from "react";

import styles from "./FeatureBox.module.css";

function FeatureBox({ imgSrc, title, description }) {
  return (
    <div className={styles.featureBox}>
      <img src={imgSrc} alt="" className={styles.featureIcon} />

      <h3 className={styles.featureTitle}>{title}</h3>

      <p className={styles.featureDescription}>{description}</p>
    </div>
  );
}

export default FeatureBox;
