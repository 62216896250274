import React from "react";

import styles from "./RiskAssessment.module.css";

function FeatureSection() {
  return (
    <section className={styles.featureSection}>
      <img
        src="images/3. img left.png"
        alt="Risk assessment process"
        className={styles.featureImage}
      />

      <div className={styles.featureContent}>
        <div className={styles.featureCard}>
          <h2 className={styles.featureTitle}>
            Identify and Mitigate Risks with Ease
          </h2>

          <p className={styles.featureDescription}>
            Risk-IO allows for easy identification of potential risks that could
            impact your organization. With comprehensive analysis and evaluation
            tools, you can develop effective mitigation plans.
          </p>
        </div>

        <div className={styles.featureGrid}>
          <div className={styles.featureCard}>
            <h3 className={styles.featureCardTitle}>Risk Identification</h3>

            <p className={styles.featureCardDescription}>
              Efficiently identify and assess potential risks that could impact
              your organization.
            </p>
          </div>

          <div className={styles.featureCard}>
            <h3 className={styles.featureCardTitle}>Risk Analysis</h3>

            <p className={styles.featureCardDescription}>
              Analyze and evaluate risks to develop effective mitigation
              strategies.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureSection;
