import React from "react";
import "./App.css"; // Per stilizzare le sezioni
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./NavBar/Navbar";
import RiskAssessment from "./Hero/RiskAssessment";
import RiskAssessmentSection from "./Feature/RiskAssessmentSection";
import KeyFeaturesSection from "./KeyFeature/KeyFeaturesSection";
import TestimonialSection from "./Testimonial/TestimonialSection";
import FeaturedIn from "./AsFeaturedIn/FeaturedIn";
import Footer from "./Footer/Footer";
import ScrollToTop from "./ScrollToTop/ScrollToTop";
import CookiePolicy from "./CookiePolicy/CookiePolicy";

const App = () => {
  return (
    <>
      <Navbar />
      <RiskAssessment />
      <RiskAssessmentSection />
      <KeyFeaturesSection />
      <TestimonialSection />
      <FeaturedIn />
      <ScrollToTop />
      {/*<CookiePolicy />*/}
      <Footer/>
    </>
  );
};

export default App;
