import React from "react";

import styles from "./RiskAssessment.module.css";

import ContactForm from "./ContactForm";

function HeroSection() {
  return (
    <section className={styles.heroSection}>
      <div className={styles.heroContent}>
        <div className={styles.heroText}>
          <h1 className={styles.heroTitle}>
            Streamline Risk Assessment with Risk-IO
          </h1>

          <p className={styles.heroDescription}>
            Risk-IO is designed to provide in-depth analysis, assessment and
            actionable insights, allowing companies to make informed decisions
            to safeguard their business operations. With our solution, you can
            streamline your risk management processes and enhance your overall
            preparedness for potential challenges whilst staying aligned with
            the ISO 31000 standard.
          </p>
        </div>

        <img
          src="images/2. box (hero img).png"
          alt="Risk assessment illustration"
          className={styles.heroImage}
        />

        <ContactForm />
      </div>
    </section>
  );
}

export default HeroSection;
