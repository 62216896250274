import React from "react";

import styles from "./TestimonialSection.module.css";

const TestimonialCard = ({
  backgroundImage,
  cardImage,
  text,
  backText,
  learnMoreText,
}) => {
  return (
    <div className={styles.testimonialCard}>
      <div className={styles.cardContent}>
        <img
          loading="lazy"
          src={backgroundImage}
          alt=""
          className={styles.backgroundImage}
        />

        <img
          loading="lazy"
          src={cardImage}
          alt="Testimonial illustration"
          className={styles.cardImage}
        />

        <div className={styles.cardText}>
          <span>{text}</span>
          <br />
          <br />
          <span className={styles.learnMore}>Learn More &#187;</span>
        </div>
      </div>

      <div className={styles.backContent}>
        <img
          loading="lazy"
          src={backgroundImage}
          alt=""
          className={styles.backgroundImage}
        />

        <div className={styles.cardText}>
            <span>{backText}</span>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
