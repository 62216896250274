import React from "react";

import styles from "./FeaturedIn.module.css";

const FeaturedIn = () => {
  return (
    <section className={styles.featuredIn}>
      <div className={styles.content}>
        <h2>As Featured In:</h2>
        <a href="https://www.securitysa.com/22878r">
          <img src="images/7. As Feature In - Smart Logo.png"></img>
        </a>
        <a href="https://www.linkedin.com/showcase/risk-io/posts/?feedView=all">
          <img src="images/7. As Feature In - Linkedin Logo.png"></img>
        </a>
      </div>
    </section>
  );
};

export default FeaturedIn;
