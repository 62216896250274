import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./RiskAssessment.module.css";

function ContactForm() {
  const [ip, setIp] = useState("");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    purpose: "",
    message: "",
    acceptTerms: false,
  });

  const industries = [
    "Financial Services",
    "Information Technology",
    "Healthcare",
    "Manufacturing",
    "Construction",
    "Other",
  ];

  useEffect(() => {
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        setIp(response.data.ip);
      })
      .catch((error) => {
        console.error("Error fetching IP address:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validazione dei campi
    if (!formData.firstName.trim()) {
      alert("Please enter your first name.");
      return;
    }

    if (!formData.lastName.trim()) {
      alert("Please enter your last name.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      alert("Please enter a valid email address.");
      return;
    }

    if (!formData.phone.trim()) {
      alert("Please enter your phone number.");
      return;
    }

    if (!formData.purpose) {
      alert("Please select a purpose.");
      return;
    }

    if (!formData.acceptTerms) {
      alert("Please accept the terms and conditions.");
      return;
    }

    const emailData = {
      sender: {
        name: "Risk-IO Contact Form",
        email: "info@zuluconsultingroup.com",
      },
      to: [{ email: "info@zuluconsultingroup.com" }],
      subject: "New contact from Risk-IO",
      htmlContent: `
        <h3>New message from ${formData.firstName} ${formData.lastName}<h3>
        <p><strong>Contact:</strong> ${formData.email}</p>
        <p><strong>Phone:</strong> ${formData.phone}</p>
        <p><strong>Purpose:</strong> ${formData.purpose}</p>
        <p><strong>Message:</strong> ${formData.message}</p>
      `,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
        "api-key":
          "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",
      },
    };

    axios
      .post("https://api.brevo.com/v3/smtp/email", emailData, config)
      .then((response) => {
        alert("Email sent successfully!");

        // Chiamata API per inviare il consenso a Iubenda
        const iubendaData = {
          subject: {
            email: formData.email,
            first_name: formData.firstName,
            last_name: formData.lastName,
          },
          preferences: {
            terms: formData.acceptTerms,
          },
          ip_address: ip
        };

        return axios.post("https://consent.iubenda.com/consent", iubendaData, {
          headers: {
            "Content-Type": "application/json",
            ApiKey: "PlmUsOWFP9ah8TptFWz844M6gHzt7PpB",
          },
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          purpose: "",
          message: "",
          acceptTerms: false,
        });
      });
  };

  return (
    <form
      className={styles.contactForm}
      onSubmit={handleSubmit}
      id="contact-form"
    >
      <h2 className={styles.formTitle}>CONTACT US</h2>

      <div className={styles.formRow}>
        <div className={styles.formGroup}>
          <label htmlFor="firstName" className={styles.visuallyHidden}>
            First Name
          </label>

          <input
            type="text"
            id="firstName"
            name="firstName"
            className={styles.formInput}
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleChange}
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="lastName" className={styles.visuallyHidden}>
            Last Name
          </label>

          <input
            type="text"
            id="lastName"
            name="lastName"
            className={styles.formInput}
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className={styles.formRow}>
        <div className={styles.formGroup}>
          <label htmlFor="email" className={styles.visuallyHidden}>
            Business Email
          </label>

          <input
            type="email"
            id="email"
            name="email"
            className={styles.formInput}
            placeholder="Business Email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="phone" className={styles.visuallyHidden}>
            Phone Number
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            className={styles.formInput}
            placeholder="Phone Number"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="purpose" className={styles.visuallyHidden}>
          Purpose of use
        </label>

        <select
          id="purpose"
          name="purpose"
          className={styles.formSelect}
          value={formData.purpose}
          onChange={handleChange}
        >
          <option value="">Purpose of use - select one</option>

          {industries.map((industry, index) => (
            <option key={index} value={industry}>
              {industry}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="message" className={styles.visuallyHidden}>
          Message
        </label>

        <textarea
          id="message"
          name="message"
          className={styles.formTextarea}
          placeholder="Type your message..."
          value={formData.message}
          onChange={handleChange}
        ></textarea>
      </div>

      <div className={styles.formFooter}>
        <div className={styles.formCheckbox}>
          <input
            type="checkbox"
            id="acceptTerms"
            name="acceptTerms"
            className={styles.checkboxInput}
            checked={formData.acceptTerms}
            onChange={handleChange}
          />

          <label htmlFor="acceptTerms" className={styles.checkboxLabel}>
            I accept T&C
          </label>
        </div>

        <button
          type="submit"
          className={styles.submitButton}
          id="contact-submit"
        >
          SUBMIT
        </button>
      </div>
    </form>
  );
}

export default ContactForm;
