import React from "react";

import styles from "./RiskAssessment.module.css";

function RiskAnalysisSection() {
  return (
    <section className={styles.riskAnalysisSection}>
      <div className={styles.disappear}></div>

      <div className={styles.riskAnalysisContent}>
        <div className={styles.riskAnalysisCard}>
          <h2 className={styles.riskAnalysisTitle}>Real-time Risk Analysis</h2>

          <p className={styles.riskAnalysisDescription}>
            Risk-IO delivers a comprehensive assessment of potential risks,
            empowering you to make informed decisions. With real-time risk
            determination, assessment, and management for your assets and
            processes, our solutions keep you ahead of emerging threats and
            enables proactive mitigation of potential issues.
          </p>
        </div>

        <div className={styles.riskAnalysisGrid}>
          <div className={`${styles.riskAnalysisCard} mb-0`}>
            <h3 className={styles.riskAnalysisCardTitle}>60% Faster</h3>

            <p className={styles.riskAnalysisCardDescription}>
              risk assessments with our intuitive interface and optimized
              backend processes.
            </p>
          </div>

          <div className={`${styles.riskAnalysisCard} mb-0`}>
            <h3 className={styles.riskAnalysisCardTitle}>
              Syncronized 
            </h3>
            <p className={styles.riskAnalysisCardDescription}>
              integration between modules provides a complete solution for the risk
              management process and seamless workflow.
            </p>
          </div>
        </div>
      </div>

      <img
        src="images/3. img right.png"
        className={styles.riskAnalysisImage}
        alt="Risk Analysis"
      />
    </section>
  );
}

export default RiskAnalysisSection;
