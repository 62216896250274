import React from "react";
import styles from "./FlipCard.module.css";

const FlipCard = ({ title, backText }) => {
  return (
    <div className={styles.flipCard}>
      <div className={styles.flipCardInner}>
        <div className={styles.flipCardFront}>
          <h2 className={styles.frontTitle}>{title}</h2>
          <span className={styles.learnMore}>
            Learn More &#187;
          </span>
        </div>
        <div className={styles.flipCardBack}>
          <p className={styles.backText}>{backText}</p>
        </div>
      </div>
    </div>
  );
};

const FlipCardContainer = () => {
  const features = [
    {
      backText:
        "Risk-IO provides real-time determination, assessment and management of risks for assets and processes, helping you stay ahead of potential threats and take proactive measures.",
      title: "Real-Time Risk Management",
    },
    {
      backText:
        "Adapt and scale Risk-IO to suit your evolving business needs, offering flexibility and long-term utility, with customizable features and settings.",
      title: "Customizable and Scalable",
    },
    {
      backText:
        "Risk-IO aligns with the ISO 31000 Standard, ensuring your risk management practices meet globally recognized guidelines for effectiveness and reliability.",
      title: "ISO 31000 Standard Compliance",
    },
    {
      backText:
        "Benefit from fully automated reporting in real-time, multi-currency support and automated GANTT chart creation for effective planning. Leverage multifactor risk analysis and mitigation solutions tailored to geographic areas.",
      title: "Automated Reporting and Advanced Analysis",
    },
  ];

  return (
    <div className={styles.flipCardContainer}>
      {features.map((feature, index) => (
        <FlipCard
          key={index}
          title={feature.title}
          backText={feature.backText}
        />
      ))}
    </div>
  );
};

export default FlipCardContainer;
