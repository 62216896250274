import React from "react";

import styles from "./RiskAssessment.module.css";

import HeroSection from "./HeroSection"

import FeatureSection from "./FeatureSection";

import RiskAnalysisSection from "./RiskAnalysisSection";

function RiskAssessment() {
  return (
    <main className={styles.riskAssessmentMain}>
      <HeroSection />

      <FeatureSection />

      <RiskAnalysisSection />
    </main>
  );
}

export default RiskAssessment;
