import React from "react";

import styles from "./TestimonialSection.module.css";

import TestimonialCard from "./TestimonialCard";

const testimonialData = [
  {
    backgroundImage: "images/5 (6). background card no border, no radius.png",

    cardImage: "images/6. Testimonial img 1.png",

    text: "A prominent financial institution successfully implemented Risk-IO in an effort to manage their operational risks with specific focus on fraud and data breaches amongst employees.",

    learnMoreText: "Learn More >>",

    backText:
      "A prominent financial institution successfully implemented Risk-IO in an effort to manage theri operational risks with specific focus on fraud and data breaches amongst employees. After the initial risk assessment was conducted, using Risk-IO, the company were able to identify legitimate shortfalls in theri existing processes and opted for continued use of the application for ongoing assessments on a more regular basis. By harnessing the Communications and Incident Register Modules of Risk-IO, the company were able to successfully reduce fraud cases by approximately 35% within a quarter. The Communications Module also proved to be a more secure channel for the company who have opted for this as their preferred method for internal communications. The Risk-IO deployment in this case led to an enhanced risk management approach for the company which resulted in compliance with local laws and regulations which boosted client confidence for the firm.",
  },

  {
    backgroundImage: "images/5 (6). background card no border, no radius.png",

    cardImage: "images/6. Testimonial img 2.png",

    text: "A multinational corporation is currently using the Risk-IO application to enhance physical security at their headquarters.",

    learnMoreText: "Learn More >>",

    backText:
      "A multinational corporation is currently using the Risk-IO application to enhance physical security at thei headquarters. Risk-IO's customized checklist feature allowed the company to conduct a thorough risk assessment which allowed them to identify and assess vulnerabilities in the building along with a detailed risk assessment plan with prioritised mitigations. Risk-IO's Incident Register module allowed the company to reduce unauthorized access incidents by 40% whilst the communications module allowed for swift reporting and resolution of security breaches which led to an overall improvement in safety and security. The company's proactive approach facilitated a vast enhancement in physical security which in turn boosted employee confidence and trust in the workplace.",
  },

  {
    backgroundImage: "images/5 (6). background card no border, no radius.png",

    cardImage: "images/6. Testimonial img 3.png",

    text: "The Risk-IO app was deployed by an auto manufacturer in an effort to enhance workplace safety.",

    learnMoreText: "Learn More >>",

    backText:
      "The Risk-IO app deployed by an auto manufacturer in an effort to enhance workplace safety. Utilizing the customized checklist feature, the manufacturer was able to detect potential hazards on the assebly line and through the use of the incident register module they were able to track incidents and expedite resolutions. Overall the use of Risk-IO led to 40% decrease in machine-related accidents within a 6 month period. The use of Risk-IO let to heightened oversight which allowed the company to redistribute part of their second quarter budget towards safety improvements and measures. Risk-IO's communication module allowed a timeous flow of information between employees and supervisors regarding safety and security matters which led to an overall improvement in productivity with effective mitigation of issues as they arose.",
  },

  {
    backgroundImage: "images/5 (6). background card no border, no radius.png",

    cardImage: "images/6. Testimonial img 4.png",

    text: "Site safety was a major concern for a construction company, and we were approached to set up Risk-IO to improve their safety score and overall safety of the construction workers.",

    learnMoreText: "Learn More >>",

    backText: "Site safety was a major concern for a construction company, and we were approached to set up Risk-IO to improve their safety score and overall safety of the construction workers. After successful initialization, Risk-IO was able to reduce safety-related accidents by half within the space of a year. A two-pronged approach was employed in this case, namely a reactive approach, through Risk-IO's Incident Register Module, as well as a proactive approach using a customized checklist through the Risk Assessment Module of the app. A direct result of the Risk-IO deployment was a decrease in safety-related expenses which was a brought to light by the application's built-in budgeting feature in the Risk Assessment Module. The company also saw improvements in overall communication and an ability to mitigate risks earlier, thanks to the Communications Module of Risk-IO."
  },
];

const TestimonialSection = () => {
  return (
    <section className={styles.testimonialSection} id="atWork">
      <h2 className={styles.header}>
        Comprehensive Risk Management for Every Industry
      </h2>

      <p className={styles.description}>
        Risk-IO's flexible design approach allows for customization that suits
        any industry. The simple interface makes the platform easy to use for
        any individual regardless of their training or background, helping you
        navigate the complexities of risk management with ease. Embrace a
        smarter, more efficient approach to risk management today.
      </p>

      <div className={styles.testimonialContainer}>
        {testimonialData.map((testimonial, index) => (
          <TestimonialCard
            key={index}
            backgroundImage={testimonial.backgroundImage}
            cardImage={testimonial.cardImage}
            text={testimonial.text}
            backText={testimonial.backText}
            learnMoreText={testimonial.learnMoreText}
          />
        ))}
      </div>
    </section>
  );
};

export default TestimonialSection;
